<adm-app-shell-page>

  <ng-template admShellPageTitle>
    Détails du champs <span class="inline-block">
   <nit-document-field
     [fieldIdentificationRequest]="(fieldRequest$ | async) || undefined"
     [showRequestValue]="false"
   >
  </nit-document-field>

  </span>
  </ng-template>

  <ng-template admShellHeaderToolbarLeft>
    <div *ngIf="(fieldRequest$ | async)?.requestStatus === 'PROBLEM'"
         class="flex-0 mr-3">
      <p-badge value="Champ problématique"></p-badge>
    </div>

    <div class="flex-0 mx-3">
      <button (click)="onRefreshClick()" class="p-button-text p-button-plain"
              icon="pi pi-refresh"
              label="Rafraichir"
              pButton
              type="button"
      ></button>
    </div>
  </ng-template>

  <ng-template admShellHeaderToolbarRight>
    <button (click)="logDialogVisible = true"
            class="flex-0 p-button-text p-button-plain"
            icon="pi pi-list"
            label="Log"
            pButton
            type="button"
    ></button>
  </ng-template>

  <div class="field-request-details-content h-full w-full relative overflow-auto flex ">

    <div class="field-request-form flex-0">
      <adm-field-request-details-form [ngModel]="fieldRequest$ | async"
      ></adm-field-request-details-form>

      <div
        *ngIf=" (fieldRequest$ | async)?.requestStatus != WsFieldIdentificationRequestStatus.Archived"
        class="admin-override-form p-component m-3">
        <h2>Indexez le champ</h2>
        <p *ngIf="(dataProblem$ | async) != null">Cela résoudra le problème</p>
        <div class="input-row my-3">
          <nit-indexing-field-input [indexedFieldModel]="(adminIndexerValue$ | async) || undefined"
          ></nit-indexing-field-input>
        </div>
        <div class="actions">
          <button (click)="onSaveValueCLick()"
                  class="p-button-text"
                  icon="pi pi-save"
                  label="Enregistrer"
                  pButton
                  type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="field-values-list flex-1">
      <h2>Valeurs reçues</h2>
      <nit-field-value-list [initialSearchFilter]="{}"
                            [searchFunction]="fieldValueSearchFunction"
      ></nit-field-value-list>
    </div>
  </div>

  <p-dialog #logDialog [(visible)]="logDialogVisible"
            [closable]="true"
            header="Log"
  >
    <div class="log-dialog-content" style="width: 50rem; height: 30rem;">
      <nit-log-lines-list [searchFunction]="logLineSearchFunction"></nit-log-lines-list>
    </div>
  </p-dialog>
</adm-app-shell-page>
