<p-card class="w-full p-4 mr-6">
  <ng-template pTemplate="title">
    <h2 class="text-2xl font-bold mb-4">Informations de base</h2>
  </ng-template>
  <ng-template pTemplate="content">
    <form *ngIf="value" class="flex flex-column gap-4">
        <div>
          <label for="creationTime" class="block font-bold mb-2">Date de création</label>
          <div id="creationTime">{{ value.creationTime | date:'full' }}</div>
        </div>

        <div>
          <label for="displayOrder" class="block font-bold mb-2">Ordre d'affichage</label>
          <div id="displayOrder">{{ value.displayOrder | number:'.0-0' }}</div>
        </div>

        <div>
          <label for="requiredIdentificationsCount" class="block font-bold mb-2">Indexations requises</label>
          <div id="requiredIdentificationsCount">{{ value.requiredIdentificationsCount | number:'.0-0' }}</div>
        </div>

        <div>
          <label for="requiredMatchingValuesCount" class="block font-bold mb-2">Matchings requis</label>
          <div id="requiredMatchingValuesCount">{{ value.requiredMatchingValuesCount | number:'.0-0' }}</div>
        </div>

        <div>
          <label for="targetGroup" class="block font-bold mb-2">Groupe cible</label>
          <div id="targetGroup">{{ value.targetGroup }}</div>
        </div>

        <div>
          <label for="requestStatus" class="block font-bold mb-2">Statut</label>
          <nit-field-request-status [value]="value.requestStatus || undefined" id="requestStatus"></nit-field-request-status>
        </div>

        <div>
          <label for="blocIndex" class="block font-bold mb-2">Bloc index</label>
          <div id="blocIndex">{{ value.blocIndex }}</div>
        </div>

        <div>
          <label for="valueRequired" class="block font-bold mb-2">Valeur requise</label>
          <div id="valueRequired">{{ value.valueRequired }}</div>
        </div>

        <div class="col-span-1 md:col-span-2 lg:col-span-3">
          <label for="identifiedValue" class="block font-bold mb-2">Valeur identifiée</label>
          <nit-field-request-value [requestRef]="{id: value.id!}" id="identifiedValue"></nit-field-request-value>
        </div>

        <div *ngIf="value.fieldProblemType" class="col-span-1 md:col-span-2 lg:col-span-3">
          <label for="fieldProblemType" class="block font-bold mb-2">Type de problème</label>
          <nit-field-problem-type
            [fieldType]="(documentField$ | async)?.fieldType || undefined"
            [problemType]="value.fieldProblemType"
            id="fieldProblemType"
          ></nit-field-problem-type>
        </div>
    </form>
  </ng-template>
</p-card>
